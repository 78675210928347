<template>
  <header>
    <div class="background-image" :style="{ backgroundImage: randomImage }">
      <div class="more-options position-relative wrap-project edit-bg">
        <div class="btn-group dropright">
          <button
            type="button"
            class="btn btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              class="edit-icon pt-4 pr-4 pl-4 pb-0 position-relative"
              src="/images/edit.svg"
              data-toggle="tooltip"
              data-placement="bottom"
              :title="$t('dash.profile_2.professional_background_edit_tip')"
            />
            <!-- <img src="/images/edit.svg" alt="" class="position-relative"> -->
          </button>
          <div class="dropdown-menu">
            <ul>
              <input
                type="file"
                name="background-image"
                ref="background"
                class="hidden d-none"
                @change="handleEdit('backgroundImage')"
              />
              <li @click="$refs.background.click()">
                {{ $t("dash.profile_2.editar") }}
              </li>
              <li href="javascript:;" @click="deleteBackground()">
                {{ $t("dash.profile_2.excluir") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="loading" :class="['container', 'containerLoading']">
        <sync-loader
          class="loader-container"
          :loading="loading"
          :color="loaderColor"
          :size="loaderSize"
        />
      </div>
      <div
        v-else
        :class="[
          { expanded: expanded },
          { editing: edit.skills },
          'container',
          'loading',
        ]"
      >
        <img
          :src="avatar"
          alt="Profile Picture"
          class="picture"
          @click="$refs.profile.click()"
        />
        <input
          type="file"
          name="profile-picture"
          ref="profile"
          class="hidden"
          @change="handleEdit('profilePicture')"
        />
        <div class="main-info-container">
          <div class="main-info">
            <h2 class="name pt-5 pt-lg-0 mr-3 mr-lg-0" v-if="!edit.name">
              {{ name }}
            </h2>
            <input v-else v-model.trim="$v.name.$model" class="edit-input" />
            <img
              class="edit-icon"
              :src="edit.name ? '/images/checkEdit.svg' : '/images/edit.svg'"
              @click="
                () => {
                  if ($v.name && $v.name.required) {
                    handleEdit('name');
                  }
                }
              "
            />
          </div>
        </div>
        <div class="time">
          <h3 class="creator-time">
            {{ $t("dash.profile_2.creator_since") }} {{ creatorSince }}
          </h3>
        </div>
        <div class="chips">
          <div class="selector-container" v-if="edit.skills">
            <home-select
              class="custom-input"
              name="major"
              :label="$t('dash.profile_2.habilidade')"
              :options="habilitiesList.map((hability) => hability[lang])"
              :value="major"
              @change="handleChange"
            />
            <home-select
              type="multiple"
              class="custom-input"
              name="complementary"
              :label="$t('dash.profile_2.habilidades')"
              :options="habilitiesList.map((hability) => hability.pt)"
              :value="complementary"
              @change="handleChange"
            />
          </div>
          <!-- <div
            class="selector-container"
            v-if="edit.skills"
          >
          </div> -->
          <div class="chips-container">
            <home-chip
              v-for="(skill, index) in skills"
              :key="index"
              :value="skill"
              :edit="edit.skills"
              @remove="removeSkill"
            />
            <img
              class="edit-icon"
              :src="edit.skills ? '/images/checkEdit.svg' : '/images/edit.svg'"
              @click="handleEdit('skills')"
            />
          </div>
        </div>
        <div class="bio mt-4">
          <div class="caracteres-bio"></div>
          <span class="bio-text" v-if="!edit.bio">{{ bio }}</span>
          <textarea
            v-else
            v-model="bio"
            rows="5"
            maxlength="185"
            id="bio-textarea"
          />
          <img
            class="edit-icon"
            :src="edit.bio ? '/images/checkEdit.svg' : '/images/edit.svg'"
            @click="handleEdit('bio')"
          />
        </div>
        <div v-if="network" class="socials">
          <a
            v-if="network.behance"
            :href="'https://www.behance.net/' + network.behance"
            target="_blank"
            class="mr-3"
          >
            <img
              class="social-media-icon"
              src="/images/socials/behance.svg"
              alt="Behance Icon"
            />
          </a>
          <a
            v-if="network.linkedin"
            :href="'https://www.linkedin.com/in/' + network.linkedin"
            target="_blank"
            class="mr-3"
          >
            <img
              class="social-media-icon"
              src="/images/socials/linkedin.svg"
              alt="LinkedIn Icon"
            />
          </a>
          <a
            v-if="network.instagram"
            :href="'https://www.instagram.com/' + network.instagram"
            target="_blank"
            class="mr-3"
          >
            <img
              class="social-media-icon"
              src="/images/socials/instagram.svg"
              alt="Instagram Icon"
            />
          </a>
          <a
            v-if="network.youtube"
            :href="'https://www.youtube.com/' + network.youtube"
            target="_blank"
            class="mr-3"
          >
            <img
              class="social-media-icon"
              src="/images/socials/youtube.svg"
              alt="YouTube Icon"
            />
          </a>
          <a
            v-if="network.vimeo"
            :href="'https://www.vimeo.com/' + network.vimeo"
            target="_blank"
            class="mr-3"
          >
            <i class="fab fa-vimeo social-media-icon"></i>
          </a>
          <a
            v-if="network.tiktok"
            :href="'https://www.tiktok.com/@' + network.tiktok"
            target="_blank"
            class="mr-3"
          >
            <img class="social-media-icon" src="/images/icons/tiktok.svg" alt="TikTok">
          </a>
          <a
            v-if="network.twitch"
            :href="'https://www.twitch.tv/' + network.twitch"
            target="_blank"
            class="mr-3"
          >
            <i class="fa fa-twitch social-media-icon" aria-hidden="true"></i>
          </a>
        </div>
        <div class="links">
          <div class="site">
            <span class="site-text"
              ><a :href="site" target="_blank">{{ site }}</a></span
            >
          </div>
          <div
            class="url"
            data-toggle="tooltip"
            data-placement="bottom"
            :title="$t('dash.profile_2.professional_link_edit_tip')"
          >
            <span class="pro-website" @click="copyText">{{ creatorUrl }}</span>
            <textarea ref="textToCopy" v-model="creatorUrl" name="url" id="url">
            </textarea>
            <img class="edit-icon" src="/images/ancor.svg" @click="copyText" />
            <span v-if="copied">{{ $t("dash.profile_2.area_trans") }}</span>
          </div>
          <div class="location">
            <span class="location-text">{{ location || $t("dash.profile_2.area_trans") }}</span>
          </div>
        </div>
        <div class="rating">
          <div v-if="averageReviews * 1" class="stars">
            <img src="/images/socials/stars.svg" alt="Stars Icon" />
            <span>{{ averageReviews }}</span>
          </div>
          <div
            v-if="communities != null && communities.length"
            class="shares"
            data-toggle="tooltip"
            data-placement="bottom"
            :title="$t('dash.profile_2.professional_community_tip')"
          >
            <img src="/images/socials/shares.svg" alt="Shares Icon" />
            <span>{{ communities.length }}</span>
          </div>
        </div>
        <div class="more" @click="expanded = !expanded">
          <img
            src="/images/expand.svg"
            alt="Expand Icon"
            :class="{ expanded: expanded }"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import { required, email } from "vuelidate/lib/validators";
import Vue from "vue";
import Vuelidate from "vuelidate";
import moment from "moment";

Vue.use(Vuelidate);

export default {
  name: "Header",
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      response: {},
      lang: "pt",
      name: "",
      habilitiesList: [],
      major: "",
      complementary: [],
      creatorSince: "",
      skills: [],
      bio: "",
      creatorUrl: "",
      email: "",
      network: {},
      communities: [],
      reviews: [],
      location: "",
      avatar: "",
      site: "",
      defaultBackground: "",
      backgroundIndex: Math.round(Math.random() * (3 - 1) + 1),
      backgrounds: [],
      expanded: false,
      copied: false,
      edit: {
        name: false,
        skills: false,
        bio: false,
        email: false,
        location: false,
      },
      loaderSize: "25px",
      loaderColor: "white",
      loading: true,
      profile_status: 1,
    };
  },
  components: {
    SyncLoader,
  },
  computed: {
    randomImage() {
      if (this.defaultBackground) {
        return `linear-gradient(rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 1)),
          url('/${this.defaultBackground}')`;
      }
      return `linear-gradient(rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 1)),
        url('/images/banners/creators-freelancer-nosotros-0${this.backgroundIndex}.jpg')`;
    },
    averageReviews() {
      var sum = 0;
      var ammount = 0;
      this.reviews.forEach((review) => {
        sum += review.total;
        ammount++;
      });
      if (ammount > 0) return (sum / ammount).toFixed(1);
      return 0;
    },
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  async mounted() {
    var _this = this;
    const response = await axios({
      method: "GET",
      url: `/api/v1/users/${_this.me.id}`,
    });
    console.log(response)
    //Make it parallel
    const responseHabilities = await axios({
      method: "GET",
      url: `/api/v1/specialities`,
    });
    const skillsList = response.data.data.specialities.map((e) => e[this.lang]);
    // if (response.data.data.profile.major) {
    //   skillsList.unshift(response.data.data.profile.major);
    // }
    const data = response.data.data;
    (this.skills = skillsList),
      (this.defaultBackground = data.background),
      (this.backgrounds = data.works.map((work) => work.file));
    this.habilitiesList = responseHabilities.data.data;
    this.complementary = data.specialities.map((e) => e[this.lang]);
    this.major = data.profile.major ? data.profile.major : "";
    this.response = data;
    this.bio = data.profile.bio;
    if (this.bio != null && this.bio.length > 185)
      this.bio = this.bio.substring(0, 185) + "...";
    this.site = data.profile.site;
    // this.creatorUrl = `creators.llc/c/${data.id}/${data.name}`
    this.creatorUrl = `https://creators.llc/c/${data.id}/${data.name.split(" ")[0]}`;
    this.name =
      data.profile && data.profile.perfil ? data.profile.perfil : data.name;
    this.network = data.network;
    this.communities = data.communities;
    this.reviews = data.received_reviews;
    this.creatorSince = moment(data.created_at).format("DD/MM/YYYY");
    if (data.profile.country_id == 3)
      this.location =
        data.profile.city && data.profile.country
          ? `${data.profile.city.name}, ${data.profile.country.name}`
          : " ";
    else
      this.location = data.profile.country
        ? `${data.profile.city_open}, ${data.profile.country.name}`
        : data.profile.city_open || " ";
    this.avatar = data.avatar ? "/" + data.avatar : "/images/avatar.jpg";
    this.loading = false;
    jQuery(document).on("input", ".bio textarea", function () {
      var limite = 185;
      var caracteresDigitados = jQuery(this).val().length;
      var caracteresRestantes = limite - caracteresDigitados;

      if (caracteresRestantes <= 0) {
        var comentario = jQuery(".bio textarea").val();
        jQuery(".bio textarea").val(comentario.substr(0, limite));
        jQuery(".caracteres-bio").text("Limite de 185 caracteres (0)");
      } else {
        jQuery(".caracteres-bio").text(
          "Limite de 185 caracteres (" + caracteresRestantes + ")"
        );
      }
    });

    jQuery(".bio textarea").bind("copy paste", function (e) {
      e.preventDefault();
    });

    // document.getElementById("bio-textarea").onkeypress = function (event) {
    //   if (event.keyCode == 13) {
    //     event.preventDefault();
    //   }
    // };
  },
  methods: {
    async deleteBackground() {
      var _this = this;

      if (confirm("Excluir imagem de fundo?")) {
        const response = await axios({
          method: "POST",
          url: `/api/v1/users/${_this.me.id}`,
          headers: {
            Authorization: "Bearer " + _this.TOKEN,
          },
          data: { _method: "PUT", background: null },
        });
        if (!response.data.error) {
          _this.background = null;
          location.reload();
        } else {
          console.log(response.data.error);
        }
      }
    },
    handleChange(field, value) {
      if (field === "major") {
        if (this.major === "") {
          this.skills.unshift(value);
        } else {
          this.skills.splice(0, 1, value);
        }
        this.major = value;
      }
      if (field === "complementary") {
        if (this.complementary.length > 4) {
          alert('Limite de 5 habilidades complementares');
        } else {
          this.complementary.push(value);
          this.skills.push(value);
        }
      }
    },
    async handleEdit(sectionName) {
      $(".caracteres-bio").toggleClass("show");
      var _this = this;
      const { profile } = this.$refs;
      if (this.edit[sectionName]) {
        const response =
          sectionName === "skills"
            ? await axios({
                method: "POST",
                url: `/api/v1/users/${_this.me.id}`,
                headers: {
                  Authorization: "Bearer " + _this.TOKEN,
                },
                data: {
                  _method: "PUT",
                  user_id: _this.me.id,
                  mainspeciality: this.major,
                  specialities: this.complementary
                    .map(
                      (skill) =>
                        this.habilitiesList.find(
                          (element) => element[this.lang] === skill
                        ).id
                    )
                    .join(";"),
                },
              })
            : await axios({
                method: "POST",
                url: `/api/v1/users/${_this.me.id}`,
                headers: {
                  Authorization: "Bearer " + _this.TOKEN,
                },
                data: {
                  _method: "PUT",
                  user_id: _this.me.id,
                  [sectionName]: this[sectionName],
                },
              });
      }
      if (sectionName === "profilePicture") {
        if (profile.files[0].size / 1024 > 2000) {
          alert("O arquivo é muito grande, reduza a imagem para menos de 2MB.");
          return;
        }
        const formData = new FormData();
        formData.append("avatar", profile.files[0]);
        formData.append("_method", "PUT");
        const response = await axios({
          method: "POST",
          url: `/api/v1/users/${_this.me.id}`,
          headers: {
            Authorization: "Bearer " + _this.TOKEN,
          },
          data: formData,
        });
        if (!response.data.error) {
          this.avatar = response.data.data.avatar
            ? "/" + response.data.data.avatar
            : "/images/avatar.jpg";
          this.$store.commit("setMe", response.data.data);
        }
      }
      if (sectionName === "backgroundImage") {
        const { background } = this.$refs;
        if (background.files[0].size / 1024 > 2000) {
          alert("O arquivo é muito grande, reduza a imagem para menos de 2MB.");
          return;
        }
        const formData = new FormData();
        formData.append("background", background.files[0]);
        formData.append("_method", "PUT");
        const response = await axios({
          method: "POST",
          url: `/api/v1/users/${_this.me.id}`,
          headers: {
            Authorization: "Bearer " + _this.TOKEN,
          },
          data: formData,
        });
        if (!response.data.error) {
          this.defaultBackground = response.data.data.background
            ? "/" + response.data.data.background
            : null;
          this.$store.commit("setMe", response.data.data);
          location.reload();
        }
      }
      this.edit[sectionName] = !this.edit[sectionName];
    },
    removeSkill(name, index) {
      if (name === this.major && index === 0) {
        this.major = "";
        this.skills.shift();
      } else {
        this.complementary = this.complementary.filter((e) => e !== name);
        this.skills = this.skills.filter(
          (e, index) => e !== name || index === 0
        );
      }
    },
    copyText() {
      const { textToCopy } = this.$refs;
      textToCopy.select();
      document.execCommand("copy");
      this.copied = true;
      setTimeout(() => (this.copied = false), 2000);
    },
    
  },
};
</script>



<style lang="scss" scoped>
@keyframes expansion {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}
@keyframes loading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.edit-bg {
  .dropdown-menu {
    right: -32px;
    left: auto !important;
    top: 60px !important;
    padding: 10px !important;
    height: 65px !important;
    @media (max-width: 991px) {
      right: -50px;
    }
  }
  ul li {
    font-size: 1em;
  }
}
header {
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: 0;
  .background-image {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    .editing {
      transition: 0.2s;
      grid-template-rows: 200px 75px 20px auto 175px !important;
    }
    .containerLoading {
      grid-template: "loader" 570px / 100% !important;
      .loader-container {
        align-self: center;
        justify-self: center;
        grid-area: loader;
      }
    }
    //For loading animation
    .loading {
      animation-timing-function: ease-in-out;
      animation-name: loading;
      animation-duration: 0.5s;
    }
    .container {
      color: #ffffff;
      display: grid;
      grid-template-areas:
        "picture picture"
        "main socials"
        "time time"
        "chips links"
        "bio rating";
      grid-template-rows:
        200px
        75px
        20px
        100px
        215px;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      max-width: 1120px;
      margin: 0 auto;
      padding: 40px 0;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 768px) {
        grid-template:
          "main" auto
          "picture" auto
          "rating" auto
          "time" auto
          "links" auto
          "socials" auto
          "chips" auto
          "bio" auto
          "more" auto
          / 1fr;
        justify-items: center;
        padding: 80px;
        &:not(.expanded) {
          .socials,
          .chips-container,
          .bio {
            max-height: 0;
            opacity: 0;
            margin: 0;
          }
        }
      }
      .picture {
        border-radius: 50%;
        grid-area: picture;
        width: 150px;
        height: 150px;
        object-fit: cover;
        cursor: pointer;
      }
      .hidden {
        display: none;
      }
      .main-info-container {
        display: flex;
        justify-content: flex-start;
      }
      .main-info {
        grid-area: main;
        display: flex;
        align-items: center;
        h2 {
          font-size: 3em;
          margin-bottom: 5px;
          margin-right: 10px;
          @media screen and (max-width: 768px) {
            font-size: 2em;
            font-weight: normal;
            margin-bottom: 40px;
            margin-right: 0px;
          }
        }
      }
      .time {
        grid-area: time;
        h3 {
          font-size: 1em;
          font-weight: normal;
          @media screen and (max-width: 768px) {
            margin-bottom: 20px;
            text-align: center;
          }
        }
      }
      .chips {
        padding-top: 20px;
        place-self: center stretch;
        .custom-input {
          padding: 0;
          margin-bottom: 0 !important;
        }
        .selector-container {
          width: 100%;
          display: inline-block;
          font-size: 16px;
          animation-name: expansion;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          .custom-input {
            width: 100%;
          }
        }
        .chips-container {
          flex-wrap: wrap;
          display: flex;
          align-items: center;
          grid-area: chips;
          transition: all 0.4s ease-in-out;
          @media screen and (max-width: 768px) {
            justify-content: center;
            margin: 20px 0;
            overflow: hidden;
            max-height: 250px;
          }
          .chip {
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 20px;
            font-size: 0.8em;
            padding: 5px 10px;
            line-height: 3em;
            &:not(:las250t-child) {
              margin-right: 10px;
            }
          }
        }
      }
      .bio {
        transition: all 0.4s ease-in-out;
        grid-area: bio;
        @media screen and (max-width: 768px) {
          text-align: center;
          margin-bottom: 40px;
          max-height: 300px;
          overflow: hidden;
        }
      }
      .caracteres-bio {
        display: none;
        &.show {
          display: block;
        }
      }

      .socials {
        grid-area: socials;
        align-self: center;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        justify-content: start;
        transition: all 0.4s ease-in-out;
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(3, 1fr);
          grid-auto-flow: row;
          grid-row-gap: 20px;
          margin-bottom: 20px;
          overflow: hidden;
          max-height: 500px;
        }
        img {
          width: 44px;
          height: 44px;
        }
        .fa,
        .fab {
          font-size: 1.4em;
        }
        img,
        .fa,
        .fab {
          cursor: pointer;
          transition: transform 0.2s ease-in-out;
          padding: 10px;
          background-color: #7f7f7f;
          border-radius: 50%;

          &:hover {
            transform: scale(1.1);
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
          @media screen and (max-width: 768px) {
            margin: 0 10px;
          }
        }
      }
      .links {
        position: relative;
        label {
          display: inline;
        }
        grid-area: links;
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
          .email {
            display: none;
          }
        }
        div {
          align-items: center;
          display: flex;
          margin: 10px 0;
          @media screen and (max-width: 768px) {
            justify-content: center;
          }
        }
        img {
          margin-left: 10px;
        }
        .url {
          .pro-website {
            cursor: text;
            margin: 0;
            font-size: 16px;
            text-decoration: none;
            color: white;
            &:visited,
            &:active,
            &:hover {
              color: white;
            }
          }
          textarea {
            opacity: 0;
            width: 0;
            height: 0;
            overflow: hidden;
          }
          span {
            margin-left: 5px;
            color: #7053d9;
          }
        }
      }
      .rating {
        grid-area: rating;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-bottom: 40px;
        }
        div {
          align-items: center;
          display: flex;
          &:not(:last-child) {
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
              margin-bottom: 0;
              margin-right: 20px;
            }
          }
          img {
            margin-right: 10px;
            @media screen and (max-width: 768px) {
              width: 30px;
            }
          }
          span {
            font-size: 1.25em;
          }
        }
      }
      .more {
        display: none;
        img {
          transition: transform 0.2s ease-in-out;
          &.expanded {
            transform: rotate(180deg);
          }
        }
        @media screen and (max-width: 768px) {
          display: block;
        }
      }
    }
  }
  .edit-icon {
    cursor: pointer;
  }
  textarea {
    background-color: transparent;
    width: 90%;
    line-height: initial;
    font-family: "SF Pro Display";
    font-size: 18px;
    color: #fff;
    border: 0px;
    border-bottom: 2px solid #7053d9;
    height: 45px;
    &:focus {
      outline: none;
    }
  }
  .edit-input {
    background-color: transparent;
    color: white;
    border: 0px;
    border-bottom: 2px solid #7053d9;
    font-family: "SF Pro Display";
    font-size: 16px;
    width: auto;
    &:focus {
      outline: none;
    }
  }
  .error-container {
    position: absolute;
    top: -20px;
    .error {
      height: 16px;
      font-family: "SF Pro Display";
      font-size: 16px;
      color: #7053d9;
    }
  }
  .bio-text {
    font-size: 22px !important;
    line-height: initial;
  }
  .email-text,
  .site-text,
  .location-text {
    font-size: 16px;
  }
}
</style>
