<template>
  <div>
      <img src="/images/professionals/instagram.svg" class="instagram-logo" />
      <h3 class="title">
        Coloque seus melhores posts do Instagram e deixe seu perfil ainda mais atrativo
      </h3>      
      <div class="form-group">
        <label for="exampleInputEmail1">Link do seu post (https://www.instagram.com/p/XXXXXXXXXXX/)</label>
        <input type="text" class="form-control" id="iframe" v-model="iframe">
        <small id="emailHelp" class="form-text text-muted">Coloque o link completo</small>
      </div>
      <button type="submit" class="btn btn-primary" @click="post()">Subir</button>
      
  </div>
</template>


<script type="text/javascript">

export default {
  props: ["isOpen"],
  data() {
    return {
      TOKEN: this.$store.state.token,
      iframe: "",
    };
  },
  methods: {
    async post() {
      const response = await axios({
        method: "POST",
        url: "/api/v1/instagram-posts",
        headers: {
          Authorization: "Bearer " + this.TOKEN,
        },
        data: {
          iframe: this.iframe,
        }
      });
      this.$router.go();
    },
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: white;
}
</style>
